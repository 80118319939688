<template>
  <div class="selected-list">
    <r-block
      v-if="selectedList.length"
      no-padding
      :gap="0"
    >
      <added-item
        v-for="item in selectedList"
        :key="item.id"
        :vehicle="item"
        icon-name="bdd"
        has-background
        :is-active="isActive(item)"
        @click-handler="$emit('click-handler', $event)"
      />
    </r-block>

    <r-block
      v-else
    >
      <r-text center>
        Выберите нарушения из таблицы
      </r-text>
    </r-block>
  </div>
</template>

<script>
export default {
  components: {
    addedItem: () => import('@/components/commons/monitoring/vehicle-item')
  },
  props: {
    selectedList: {
      type: Array,
      required: true
    },
    activeList: {
      type: Array,
      required: true
    }
  },
  methods: {
    isActive(id) {
      return this.activeList?.includes(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-list {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  width: 200px;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers);
  height: 100%;
  max-height: 60vh;
  overflow: auto;
}
</style>
